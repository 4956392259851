<template>
  <app-module-view>
    <template slot="body">
      <app-kpi-reports-filter @filter="onFilterSubmitted">
        <template v-slot:buttons>
          <div
            v-if="isExportButtonVisible"
            class="btn btn-info"
            @click="unparseDataToCsv"
          >
            {{ $t('video.csvExport') }}
          </div>
        </template>
      </app-kpi-reports-filter>
      <div class="card">
        <div class="card-body">
          <div>
            <div class="table-responsive">
              <table id="list-table" class="table table-striped">
                <thead>
                <tr>
                  <th width="20" class="text-left">
                    {{ $t("videoStats.list.department") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.socialNetworks") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.agencies") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.mobilePhone") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.slideShow") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.onRequest") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.shows") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.totalVideos") }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(kpiReportRow, index) in kpiReportData" :key="`kpiReportRow-${index}`">
                  <td>
                    <div class="text-left">
                                                <span>
                                                    {{ kpiReportRow.department }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.socialNetworks | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.agencies | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.mobilePhone | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.slideShow | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight2">
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.onRequest | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight2">
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.shows | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight3">
                    <div class="text-center">
                                                <span>
                                                    {{ kpiReportRow.videosCount | formatNumber }}
                                                </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import CoreApi from '../../api/core'
import ModuleView from '../../components/ModuleView'
import moment from 'moment'
import KpiReportsFilter from '../../components/video/videoStats/KpiReportsFilter'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'KpiReports',
  components: {
    appModuleView: ModuleView,
    appKpiReportsFilter: KpiReportsFilter
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isExportButtonVisible () {
      const permission =
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_KPI_REPORT_PERMISSIONS.exportButton
      return this.$store.getters['user/hasPermission'](permission)
    }
  },
  data () {
    return {
      dataLoaded: false,
      kpiReportData: {},
      filter: {
        dateFrom: null,
        dateTo: null
      }
    }
  },
  methods: {
    onFilterSubmitted (data) {
      this.filter = data
      this.fetchData()
    },
    fetchData () {
      this.dataLoaded = false
      this.kpiReportData = {}

      const url = '/videostats/kpi-report?' +
        'fromDate=' + moment(this.filter.dateFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z' +
        '&toDate=' + moment(this.filter.dateTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'

      CoreApi().get(url)
        .then(response => {
          this.kpiReportData = response.data.reports
          this.dataLoaded = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    unparseDataToCsv () {
      const rows = this.kpiReportData.map(
        kpiReportRow => [
          kpiReportRow.department,
          kpiReportRow.socialNetworks,
          kpiReportRow.agencies,
          kpiReportRow.mobilePhone,
          kpiReportRow.slideShow,
          kpiReportRow.onRequest,
          kpiReportRow.shows,
          kpiReportRow.videosCount
        ]
      )

      rows.unshift([
        this.$i18n.t('videoStats.list.department'),
        this.$i18n.t('videoStats.list.socialNetworks'),
        this.$i18n.t('videoStats.list.agencies'),
        this.$i18n.t('videoStats.list.mobilePhone'),
        this.$i18n.t('videoStats.list.slideShow'),
        this.$i18n.t('videoStats.header.onRequest'),
        this.$i18n.t('videoStats.header.shows'),
        this.$i18n.t('videoStats.header.totalVideos')
      ])

      const csv = rows.map(row => row.join(';')).join('\n')

      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      let csvURL = null
      const fileName = 'kpi-report.csv'

      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, fileName)
      } else {
        csvURL = window.URL.createObjectURL(csvData)
      }
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
    }
  }
}
</script>

<style scoped>
.highlight2 {
  background-color: #eeeece;
}

.highlight3 {
  background-color: #eeaeae;
}
</style>
